.App {
  text-align: center;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

a {
  filter: drop-shadow(0 0 0.75rem black);
  transition: all 0.25s ease-in-out;
}

a:hover {
  filter: drop-shadow(0 0 0.5rem black);
  font-size: calc(10px + 3vmin);
}

a:visited {
  color: black;
}

a:link {
  text-decoration: none;
}

@keyframes fade {
  from { opacity: 0; }
  to { opacity: 0.25; }
}

.App::after {
  content: "";
  background: url("https://emojipedia-us.s3.dualstack.us-west-1.amazonaws.com/thumbs/120/apple/279/hammer-and-wrench_1f6e0-fe0f.png");
  opacity: 0.25;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: absolute;
  z-index: -1;
  background-repeat: no-repeat;
  background-position: center;
  animation: fade 5s;
}